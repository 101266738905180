import React, { FC, ReactElement } from 'react'

export const Soldout: FC = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="85.657"
    height="85.657"
    viewBox="0 0 85.657 85.657"
  >
    <g
      id="Group_405"
      data-name="Group 405"
      transform="translate(76.107 -532.186) rotate(45)"
    >
      <path
        id="Path_796"
        data-name="Path 796"
        d="M424.607,421.6H341.523l-10.494,8.533,10.494,8.533h83.084l10.494-8.533Z"
        fill="#1d3041"
      />
      <g id="Group_404" data-name="Group 404">
        <path
          id="Path_797"
          data-name="Path 797"
          d="M346.107,430.792c-1.171-.41-2.491-1.047-2.491-2.526a2.42,2.42,0,0,1,2.606-2.252,3.423,3.423,0,0,1,2.593,1.262l-.944.934a2.3,2.3,0,0,0-1.649-.9,1.1,1.1,0,0,0-1.218.955c0,.7.626.91,1.718,1.32,1.217.456,2.229,1,2.229,2.446s-1.273,2.217-2.775,2.217a3.48,3.48,0,0,1-2.912-1.625l1.024-.865a2.429,2.429,0,0,0,1.888,1.194c.682,0,1.387-.273,1.387-.921C347.563,431.36,347.052,431.122,346.107,430.792Z"
          fill="#f7f5f1"
        />
        <path
          id="Path_798"
          data-name="Path 798"
          d="M352.618,426.15h2.708a2.55,2.55,0,1,1,0,5.1h-1.342v2.867h-1.366Zm3.959,2.549c0-.751-.489-1.252-1.422-1.252h-1.171v2.5h1.171C356.088,429.951,356.577,429.45,356.577,428.7Z"
          fill="#f7f5f1"
        />
        <path
          id="Path_799"
          data-name="Path 799"
          d="M361.325,426.15h2.616a2.441,2.441,0,0,1,2.639,2.481,2.3,2.3,0,0,1-1.774,2.388l1.99,3.094H365.2l-1.888-3h-.626v3h-1.365Zm3.867,2.481c0-.785-.546-1.184-1.422-1.184h-1.08v2.366h1.08C364.646,429.813,365.192,429.415,365.192,428.631Z"
          fill="#f7f5f1"
        />
        <path
          id="Path_800"
          data-name="Path 800"
          d="M376.014,426.15l-4.14,6.666h3.913v1.3h-6.245l4.163-6.666h-3.776v-1.3Z"
          fill="#f7f5f1"
        />
        <path
          id="Path_801"
          data-name="Path 801"
          d="M380.648,429.382h3.185v1.3h-3.185v2.137h3.527v1.3h-4.892V426.15h4.824v1.3h-3.459Z"
          fill="#f7f5f1"
        />
        <path
          id="Path_802"
          data-name="Path 802"
          d="M394.269,430.132a3.811,3.811,0,0,1-3.936,3.981h-2.571V426.15h2.571A3.812,3.812,0,0,1,394.269,430.132Zm-1.388,0a2.53,2.53,0,0,0-2.525-2.685h-1.228v5.369h1.228A2.536,2.536,0,0,0,392.881,430.132Z"
          fill="#f7f5f1"
        />
        <path
          id="Path_803"
          data-name="Path 803"
          d="M399.9,426.15h1.388l3.094,7.963h-1.377l-.728-1.888h-3.366l-.728,1.888h-1.377Zm-.5,4.824h2.389l-1.194-3.106Z"
          fill="#f7f5f1"
        />
        <path
          id="Path_804"
          data-name="Path 804"
          d="M408.791,426.15l3.981,5.484V426.15h1.365v7.963h-1.228l-3.982-5.471v5.471h-1.365V426.15Z"
          fill="#f7f5f1"
        />
        <path
          id="Path_805"
          data-name="Path 805"
          d="M419.452,429.382h3.185v1.3h-3.185v2.137h3.526v1.3h-4.891V426.15h4.823v1.3h-3.458Z"
          fill="#f7f5f1"
        />
      </g>
    </g>
  </svg>
)
