import React, { ReactElement, useContext } from 'react'
import styled from 'styled-components'
import { Carousel } from 'antd'

import { StoreItem } from '~src/models/store'

import sharedWoodModel from '~src/models/wood/Shared.wood'
import sharedMetalModel from '~src/models/metals/Shared.metal'
import sharedKnivesModel from '~src/models/knives/Shared.knives'
import LanguageContext from '~components/homepage/context/language-context'
import { KnifeSizeImage } from '~components/homepage/styled/grid-materials'
import { mediaQueries, palette } from '~components/homepage/styled/constants'

import { MagnifySvg } from '~components/homepage/svg/icons/magnify'
import { Soldout } from '~components/homepage/svg/icons/soldout'
import { SoldoutEnglish } from '~components/homepage/svg/icons/soldOutEnglish'
import {
  NextArrow,
  PrevArrow,
  StyledNextArrow,
  StyledPrevArrow,
} from './styled'

const KnifeDraft = styled.div<{ active: boolean }>`
  color: inherit;
  background: ${palette.paleGray};
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  .single-knife-image {
    height: unset;

    #peeling {
      @media (max-width: ${mediaQueries.small}) {
        & {
          width: 100px !important;
        }
      }
    }

    .color-transition {
      fill: ${({ active }): string => (active ? 'white' : '#f7f5f1')};
    }
  }
`

const Materials = styled.div`
  display: grid;
  justify-content: center;
  font-size: 11px;
  color: ${palette.darkBlue};
  margin-top: 16px;

  gap: 10px;
`

const Price = styled.div`
  font-size: 14px;
  font-family: 'Averta-Bold', sans-serif;
  color: ${palette.darkBlue};
  margin-top: 9px;
`

const PriceDivider = styled.div`
  width: 20px;
  height: 2px;
  background: ${palette.inputBorder};
  margin: 0 auto;
  margin-bottom: 6px;
`

const MagnifyWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 7px;
  transition: 0.3s;
  opacity: 0;
  z-index: 99;
  cursor: pointer;
`

const ItemContainer = styled.div`
  max-width: 325px;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  position: relative;

  .knife-name {
    font-size: 11px;
    font-family: 'Averta-Bold', sans-serif;
    color: ${palette.darkBlue};
    margin-top: 15px;
  }

  &:hover {
    ${StyledNextArrow}, ${StyledPrevArrow}, ${MagnifyWrapper} {
      opacity: 1;
    }
  }
`

const SelectedStick = styled.div`
  position: absolute;
  height: 28px;
  width: 1px;
  left: 40px;
  bottom: 0px;
  background: ${palette.darkBlue};
`

const SoldoutIcon = styled.div<{ eng: boolean }>`
  position: absolute;
  top: 0px;
  z-index: 10;
  right: 0px;
  transform: ${({ eng }): string =>
    eng
      ? 'translateX(25%) translateY(-25%)'
      : 'translateX(20%) translateY(-20%)'};
`

export const StoreItemComponent: React.FC<StoreItem & {
  itemIndex: number
  onOpenDescription: () => void
  hideFooter: boolean
  selected: boolean
  setOpenPreview: (id: React.ReactElement[]) => void
}> = ({
  images,
  knife,
  wood,
  metal,
  price,
  onOpenDescription,
  hideFooter,
  selected,
  soldOut,
  setOpenPreview,
}): ReactElement => {
  const { lang, t } = useContext(LanguageContext)

  const foundKnife = sharedKnivesModel.find(
    ({ name }): boolean => name === knife
  )
  const foundMetal = sharedMetalModel.find(
    ({ name }): boolean => name === metal
  )

  const foundWood = sharedWoodModel.find(({ name }): boolean => name === wood)
  const SvgComponent = foundKnife?.SvgKnife

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: (Math.floor(Math.random() * 5) + 10) * 1000,
  }

  const renderSlides = (height: string): object[] =>
    images.map((url): object => (
      <img
        key={url}
        src={url}
        alt={foundKnife.title[lang]}
        style={{
          width: '100%',
          maxHeight: height,
        }}
      />
    ))

  return (
    <ItemContainer style={{ pointerEvents: soldOut ? 'none' : 'unset' }}>
      {soldOut && (
        <SoldoutIcon eng={lang !== 'PL'}>
          {lang === 'PL' ? <Soldout /> : <SoldoutEnglish />}
        </SoldoutIcon>
      )}
      <MagnifyWrapper onClick={() => setOpenPreview(renderSlides('600px'))}>
        <MagnifySvg />
      </MagnifyWrapper>

      <Carousel
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        arrows
        {...sliderSettings}
        style={{
          height: 217,
          overflow: 'hidden',
          opacity: soldOut ? '0.5' : 1,
        }}
      >
        {renderSlides('217px')}
      </Carousel>
      <KnifeDraft
        active={selected}
        onClick={onOpenDescription}
        style={{ opacity: soldOut ? '0.5' : 1 }}
      >
        {selected && <SelectedStick />}
        <KnifeSizeImage className={`${foundKnife.name} single-knife-image`}>
          {SvgComponent && <SvgComponent />}
        </KnifeSizeImage>
      </KnifeDraft>

      {!hideFooter && (
        <>
          <div style={{ opacity: soldOut ? '0.5' : 1 }} className="knife-name">
            {foundKnife.title[lang]}
          </div>
          <Materials style={{ opacity: soldOut ? '0.5' : 1 }}>
            {`${foundMetal.title[lang]} | ${foundWood.title[lang]}`}
          </Materials>
          <Price style={{ opacity: soldOut ? '0.5' : 1 }}>
            <PriceDivider />
            {lang === 'PL' ? price[0] : price[1]} {t(`currency`)}
          </Price>
        </>
      )}
    </ItemContainer>
  )
}
