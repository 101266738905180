import React, { ReactElement, useContext, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { head, map } from 'lodash'
import { KnifeGrindingProfile, MetalConfig, WoodConfig } from '~src/types/knife'
import { findImages } from '~components/homepage/utils'
import { DarkButton, ResponsiveImage } from '~components/homepage/styled/shared'
import { RenderVersatilityBar } from '~components/homepage/styled/grid-materials'
import LanguageContext from '~components/homepage/context/language-context'

import sharedWoodModel from '~src/models/wood/Shared.wood'
import sharedMetalModel from '~src/models/metals/Shared.metal'
import sharedKnivesModel from '~src/models/knives/Shared.knives'
import { StoreItem } from '~src/models/store'
import { SVGImage } from '~components/homepage/svg/SVGImage'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { DescriptionContainer } from './styled'

export const KnifeDescription: React.FC<Omit<
  StoreItem & {
    chunkIndex: number
    onClose: () => void
    setOpenOrderModal: (id: number) => void
    isMobile: boolean
  },
  'previewImage'
>> = ({
  knife,
  metal,
  wood,
  price,
  onClose,
  id,
  setOpenOrderModal,
  comment,
  isMobile,
}): React.ReactElement => {
  const { lang, t } = useContext(LanguageContext)
  const query = useStaticQuery(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxHeight: 60) {
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
      }
    `
  )

  const foundKnife = sharedKnivesModel.find(
    ({ name }): boolean => name === knife
  )
  const foundMetal = sharedMetalModel.find(
    ({ name }): boolean => name === metal
  )

  const foundWood = sharedWoodModel.find(({ name }): boolean => name === wood)

  const [material, setMaterial] = useState<WoodConfig & MetalConfig>()
  const [metalPreview, woodPreview] = findImages(query, [
    foundMetal.img,
    foundWood.img,
  ])

  const materialPreviews = (
    <div className="materials-preview">
      <div
        className="material"
        onKeyPress={(): void => {}}
        role="button"
        tabIndex={0}
        onClick={(): void => setMaterial(foundMetal)}
      >
        <div className="image material-image">
          <ResponsiveImage
            src={metalPreview?.node.childImageSharp.fluid.src}
            height="55px"
            width="55px"
          />
        </div>
        <div
          className="material-name"
          style={{
            textDecoration:
              material?.name === foundMetal.name ? 'underline' : 'unset',
          }}
        >
          {' '}
          {foundMetal.title[lang]}{' '}
        </div>
      </div>
      <div
        className="material"
        onKeyPress={(): void => {}}
        role="button"
        tabIndex={0}
        onClick={(): void => setMaterial(foundWood)}
      >
        <div className="image material-image">
          <ResponsiveImage
            src={woodPreview?.node.childImageSharp.fluid.src}
            height="55px"
            width="55px"
          />
        </div>
        <div
          className="material-name"
          style={{
            textDecoration:
              material?.name === foundWood.name ? 'underline' : 'unset',
          }}
        >
          {' '}
          {foundWood.title[lang]}{' '}
        </div>
      </div>
    </div>
  )

  const mobileStyle = {
    overflow: 'auto',
    // overflowX: 'hidden',
    height: '100vh',
    display: 'block',
  }

  return (
    <DescriptionContainer
      style={isMobile ? mobileStyle : undefined}
      isMobile={isMobile}
    >
      <div
        className="close"
        onKeyPress={(): void => {}}
        role="button"
        tabIndex={0}
        onMouseDown={onClose}
      >
        <SVGImage svgProps={[KlosySVGId.cross, 'close', 12, 12]} />
      </div>

      <div className="knife-description">
        {foundKnife.description[lang].map(
          (paragraph): ReactElement => (
            <p key={`paragraph-${head(paragraph)}`}> {paragraph} </p>
          )
        )}

        <i> {comment} </i>

        {!isMobile && materialPreviews}
      </div>
      <div className="divider" />
      <div className="params-container">
        <div className="knife-params">
          <div>
            <span className="bold">{t('knife-grid.steel')}</span>:{' '}
            {foundMetal.title[lang]}{' '}
          </div>
          <div>
            <span className="bold">{t('knife-grid.wood')}</span>:{' '}
            {foundWood.title[lang]}{' '}
          </div>
          <div>
            <span className="bold">{t('knife-grid.lengthEdge')}</span>:{' '}
            {foundKnife.length}mm{' '}
            {foundKnife.lengthInch
              ? `(${foundKnife.lengthInch} ${t('knife-grid.inch')})`
              : null}
          </div>
          <div>
            <span className="bold">{t('knife-grid.widthBlade')}</span>:{' '}
            {foundKnife.height}mm{' '}
          </div>
          <div>
            <span className="bold">{t('knife-grid.weight')}</span>:{' '}
            {foundKnife.weight}g{' '}
          </div>
          <div>
            <span className="bold">{t('knife-grid.bladeProfile')}</span>:{' '}
            {foundKnife.grindingProfile
              .map((profile: KnifeGrindingProfile): string =>
                t(`knife-grid.${profile}`)
              )
              .join(' / ')}
          </div>
          <div>
            <span className="bold">{t('knife-grid.versatility')}</span>:{' '}
            {RenderVersatilityBar(foundKnife.versatility)}{' '}
          </div>
          <div>
            <span className="bold">{t('store.price')}</span>:{' '}
            <span className="price bold">
              {lang === 'PL' ? price[0] : price[1]} {t('currency')}
            </span>
          </div>
        </div>

        <DarkButton
          className="order-button"
          onClick={(): void => setOpenOrderModal(id)}
        >
          {t('store.order')}
        </DarkButton>
      </div>
      {isMobile ? (
        <div className="knife-description">{materialPreviews}</div>
      ) : null}

      {material && (
        <>
          <div className="h-divider" style={{ marginLeft: 50 }} />
          <div className="h-divider" />
          <div className="h-divider" style={{ marginRight: 50 }} />
          <div className="material-description-container">
            <div className="description-text">
              {map(
                material?.description[lang],
                (paragraph, i): ReactElement => (
                  <p key={`paragraph-material-${material.name}-${i}`}>
                    {paragraph}
                  </p>
                )
              )}
            </div>
            <div className="material-params">
              <div className="knife-params">
                {material?.care && (
                  <div>
                    <span className="bold">{t('material-grid.care')}</span>:{' '}
                    {material.care[lang]}
                  </div>
                )}
                {material.oilCare && (
                  <div>
                    <span className="bold">{t('material-grid.oilCare')}</span>:{' '}
                    {material.oilCare[lang]}
                  </div>
                )}
                {material.weight && (
                  <div>
                    <span className="bold">{t('material-grid.weight')}</span>:{' '}
                    {material.weight[lang]}
                  </div>
                )}
                {material.endurance && (
                  <div>
                    <span className="bold">{t('material-grid.endurance')}</span>
                    :{RenderVersatilityBar(material.endurance)}
                  </div>
                )}

                {material.sharpening && (
                  <div>
                    <span className="bold">
                      {t('material-grid.sharpening')}
                    </span>
                    : {material.sharpening[lang]}
                  </div>
                )}
                {material.stainless && (
                  <div>
                    <span className="bold">{t('material-grid.stainless')}</span>
                    : {RenderVersatilityBar(material.stainless)}
                  </div>
                )}
                {material.hardness && (
                  <div>
                    <span className="bold">{t('material-grid.hardness')}</span>:{' '}
                    {RenderVersatilityBar(material.hardness)}
                  </div>
                )}
                {material.edgeRetention && (
                  <div>
                    <span className="bold">
                      {t('material-grid.edgeRetention')}
                    </span>
                    : {RenderVersatilityBar(material.edgeRetention)}
                  </div>
                )}
                {material.coreStainless && (
                  <div>
                    <span className="bold">
                      {t('material-grid.coreStainless')}
                    </span>
                    : {RenderVersatilityBar(material.coreStainless)}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </DescriptionContainer>
  )
}
