import React, { ReactElement, useState, useContext } from 'react'

import { Trans } from 'react-i18next'
import { Select, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import LanguageContext from '~components/homepage/context/language-context'
import { SVGImage } from '~components/homepage/svg/SVGImage'

import sharedWoodModel from '~src/models/wood/Shared.wood'
import sharedMetalModel from '~src/models/metals/Shared.metal'
import sharedKnivesModel from '~src/models/knives/Shared.knives'

import { StoreItem, StoreItemAccessory } from '~src/models/store'
import { palette } from '~components/homepage/styled/constants'
import { DarkButton } from '~components/homepage/styled/shared'
import { sendStoreOrder } from '~src/api/email-api'
import { langIndex } from '~components/homepage/utils'
import { shippingInfo, purchase, ItemType } from '~src/analytics'
import { StoreOrderData } from '../types'
import {
  FieldRow,
  StyledModal,
  FieldLabel,
  FieldItem,
  StyledAnchorLink,
  Link,
  SelectWrapper,
} from '../../homepage/styled/order-modal-components'
import { KlosySVGId } from '../../homepage/svg/types/svg'

const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />

const { Option } = Select

interface OrderModalProps {
  active: boolean
  item: StoreItem | StoreItemAccessory
  onCloseModal: () => void
}

const HeaderWrapper = styled.div`
  margin-bottom: 55px;
`

const UpperLabel = styled.div`
  font-size: 14px;
  font-family: 'Averta-Bold', sans-serif;
`

const Checkbox = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;

  .check {
    margin-right: 10px;
    z-index: 10;
    opacity: 0;
  }

  svg.visible {
    opacity: 1;
    transition: 0.3;
  }

  .empty-check {
    position: absolute;
    width: 15px;
    height: 15px;
    border: 1px solid ${palette.inputBorder};
    z-index: 9;
    background: white;
  }
`

const OrderModal = ({
  active,
  item = {},
  onCloseModal,
}: OrderModalProps): ReactElement => {
  const { t, lang } = useContext(LanguageContext)

  const [sendOrderPending, setSendOrderPending] = useState<boolean>(false)
  // eslint-disable-next-line
  const [orderSentMessage, setOrderSentMessage] = useState<null | string>(null)

  const foundKnife = sharedKnivesModel.find(
    ({ name }): boolean => name === item?.knife
  )
  const foundMetal = sharedMetalModel.find(
    ({ name }): boolean => name === item?.metal
  )

  const foundWood = sharedWoodModel.find(
    ({ name }): boolean => name === item?.wood
  )

  /**
   * Knife order config
   */
  const [formData, setFormData] = useState<StoreOrderData>({
    shipping: 'shipping',
    name: '',
    email: '',
    phone: '',
    street: '',
    postalCode: '',
    city: '',
    comment: '',
    invoiceRequired: false,
    company: '',
    taxNo: '',
    companyAddress: '',
    companyPostalCode: '',
    companyCity: '',
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
  })

  const lngIndex = langIndex(lang)

  /**
   * Validate and send data to klosy email.
   */
  const onSendOrder = async (): Promise<void> => {
    const validateFields = {
      name: formData.name === '',
      email: !/\S+@\S+\.\S+/.test(formData.email),
      phone: formData.phone === '',
    }
    setFormErrors(validateFields)

    if (validateFields.name || validateFields.email) return

    setSendOrderPending(true)

    const resp = await sendStoreOrder({
      ...formData,
      price: `${item.price[0]}zł / ${item.price[1]}eur`,
      knife: item?.knife || item?.name[0],
      itemId: item.id,
      storeId: item.comment,
      parsedKnifeName:
        (foundKnife?.title?.PL as string) || item?.name[lngIndex],
    })

    const type: ItemType = item.knife ? 'knife' : 'accessory'

    shippingInfo(type, [item], formData.shipping)
    purchase(
      type,
      [item],
      lngIndex ? 'EUR' : 'PLN',
      undefined,
      'klosy.pl/store'
    )

    setSendOrderPending(false)
    setOrderSentMessage(
      resp.status === 200
        ? t('store.order-sent.title')
        : t('order-modal.orderSentMessageFail')
    )
  }

  const modalProps = {
    visible: active,
    footer: null,
    maskClosable: false,
    title: null,
    onCancel: (): void => onCloseModal(),
    closeIcon: <SVGImage svgProps={[KlosySVGId.cross, 'close', 12, 12]} />,
    width: '900px',
    centered: true,
  }

  const generateField = (
    name: string,
    textarea = false,
    placeholder = undefined
  ): React.ReactElement => (
    <>
      <SVGImage
        svgProps={[KlosySVGId.errorSign, 'error-sign', 17, 17]}
        className={formErrors[name] ? 'error-sign active' : 'error-sign'}
      />

      {textarea ? (
        <textarea
          placeholder={
            placeholder ? t(placeholder) : t(`store.order-modal.${name}`)
          }
          value={formData[name]}
          onChange={(e: any): void =>
            setFormData({ ...formData, [name]: e.target.value })
          }
        />
      ) : (
        <input
          id={name}
          type="text"
          placeholder={t(`store.order-modal.${name}`)}
          value={formData[name]}
          onChange={(e: any): void =>
            setFormData({ ...formData, [name]: e.target.value })
          }
        />
      )}
    </>
  )

  const shippingOptions = [
    {
      value: 'shipping',
      label: 'store.order-modal.shippingLabel',
    },
    {
      value: 'store',
      label: 'store.order-modal.storeLabel',
    },
  ]

  return ((): ReactElement => {
    const sentMessage = (
      <>
        <h3> {orderSentMessage} </h3>
        <div className="sub-title-store">{t('store.order-sent.content1')}</div>
        <br />
        <div className="sub-title-store">{t('store.order-sent.content2')}</div>
        <div className="sub-title-store bold">
          {t('store.order-sent.phone')}
        </div>
        <br />
        <div className="sub-title-store">
          <i>{t('store.order-sent.underline')}</i>
        </div>
      </>
    )

    const form = (
      <>
        <HeaderWrapper>
          <div className="header">{t('store.order-modal.title')}</div>
          <div className="title">{t('store.order-modal.subtitle')}</div>
          <div className="title">{t('store.order-modal.subtitle2')}</div>
        </HeaderWrapper>

        <FieldRow>
          <FieldLabel bold>{t('store.order-modal.yourOrder')}</FieldLabel>
          {foundKnife ? (
            <FieldItem>
              {foundKnife?.title[lang]} - {foundMetal?.title[lang]} -{' '}
              {foundWood?.title[lang]}
            </FieldItem>
          ) : (
            <FieldItem>{item?.name?.[lngIndex]}</FieldItem>
          )}
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('store.order-modal.shipping')}</FieldLabel>
          <SelectWrapper
            className="generic-dropdown"
            style={{ textAlign: 'left', maxWidth: '100%' }}
          >
            <Select
              getPopupContainer={(trigger): any => trigger.parentNode}
              showArrow={false}
              value={formData.shipping}
              onChange={(value: string): void =>
                setFormData(
                  (v): StoreOrderData => ({
                    ...v,
                    shipping: value,
                  })
                )
              }
            >
              <Option value={shippingOptions[0].value}>
                <span className="material-name">
                  {t(shippingOptions[0].label)}
                </span>
              </Option>
              <Option value={shippingOptions[1].value}>
                <span className="material-name">
                  {t(shippingOptions[1].label)}
                </span>
              </Option>
            </Select>
            <SVGImage
              svgProps={[KlosySVGId.arrowDown, 'dropdown-arrow', 19, 11]}
            />
          </SelectWrapper>
        </FieldRow>

        <FieldRow>
          <FieldLabel>
            <div>
              <UpperLabel>{t('store.order-modal.label-header')}</UpperLabel>
              {t('store.order-modal.name')}
            </div>
          </FieldLabel>
          <FieldItem>{generateField('name')}</FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('store.order-modal.email')}</FieldLabel>
          <FieldItem>{generateField('email')}</FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('store.order-modal.phone')}</FieldLabel>
          <FieldItem>{generateField('phone')}</FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('store.order-modal.street')}</FieldLabel>
          <FieldItem>{generateField('street')}</FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('store.order-modal.postalCode')}</FieldLabel>
          <FieldItem>{generateField('postalCode')}</FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('store.order-modal.city')}</FieldLabel>
          <FieldItem>{generateField('city')}</FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel top>{t('store.order-modal.comment')}</FieldLabel>
          <FieldItem>
            {generateField(
              'comment',
              true,
              'store.order-modal.commentPlaceholder'
            )}
          </FieldItem>
        </FieldRow>

        <FieldRow style={{ margin: '30px auto' }}>
          <FieldLabel bold>{t('store.order-modal.invoiceLabel')}</FieldLabel>
          <FieldItem
            onClick={(): void =>
              setFormData(
                (v): StoreOrderData => ({
                  ...v,
                  invoiceRequired: !v.invoiceRequired,
                })
              )
            }
          >
            <Checkbox>
              <SVGImage
                className={formData.invoiceRequired ? 'visible check' : 'check'}
                svgProps={[KlosySVGId.checked, 'checked', 15, 15]}
              />
              <div className="empty-check"></div>{' '}
              {t('store.order-modal.invoiceRequired')}
            </Checkbox>
          </FieldItem>
        </FieldRow>

        {formData.invoiceRequired && (
          <>
            <FieldRow>
              <FieldLabel>
                <div>
                  <UpperLabel>
                    {t('store.order-modal.company-label-header')}
                  </UpperLabel>
                  {t('store.order-modal.company')}
                </div>
              </FieldLabel>
              <FieldItem>{generateField('company')}</FieldItem>
            </FieldRow>

            <FieldRow>
              <FieldLabel>{t('store.order-modal.taxNo')}</FieldLabel>
              <FieldItem>{generateField('taxNo')}</FieldItem>
            </FieldRow>

            <FieldRow>
              <FieldLabel>{t('store.order-modal.companyAddress')}</FieldLabel>
              <FieldItem>{generateField('companyAddress')}</FieldItem>
            </FieldRow>

            <FieldRow>
              <FieldLabel>
                {t('store.order-modal.companyPostalCode')}
              </FieldLabel>
              <FieldItem>{generateField('companyPostalCode')}</FieldItem>
            </FieldRow>

            <FieldRow>
              <FieldLabel>{t('store.order-modal.companyCity')}</FieldLabel>
              <FieldItem>{generateField('companyCity')}</FieldItem>
            </FieldRow>
          </>
        )}

        <FieldRow style={{ textAlign: 'right', marginTop: 35 }}>
          <div style={{ textAlign: 'right' }}>
            <FieldLabel bold color={palette.darkBlue} fs="14px">
              {t('store.order-modal.price')}
            </FieldLabel>
            <FieldLabel bold color={palette.darkBlue} fs="14px">
              {t('store.order-modal.price2')}
            </FieldLabel>
          </div>

          <FieldItem bold color={palette.darkBlue} fs="25px">
            {lang === 'PL' ? item?.price?.[0] : item?.price?.[1]}{' '}
            {lang === 'PL' ? 'zł' : '€'}
          </FieldItem>
        </FieldRow>

        <DarkButton
          onClick={(): Promise<void> => onSendOrder()}
          style={{ maxWidth: '300px', marginTop: 30 }}
        >
          {sendOrderPending ? (
            <Spin indicator={antIcon} />
          ) : (
            t('store.order-modal.order-button')
          )}
        </DarkButton>
      </>
    )

    return (
      <StyledModal {...modalProps}>
        {orderSentMessage ? sentMessage : form}

        <div className="footer">
          <Trans i18nKey="order-modal.footer">
            {[
              <StyledAnchorLink
                href="#faq"
                onClick={(): void => onCloseModal()}
              ></StyledAnchorLink>,
              <Link href="mailto:klosy.info@gmail.com"></Link>,
              <Link href="tel:48600113805"></Link>,
            ]}
          </Trans>
        </div>
      </StyledModal>
    )
  })()
}

export default OrderModal
