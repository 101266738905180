import React, { ReactElement, useEffect, useState } from 'react'

import '../i18n/i18n'

import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { chunk } from 'lodash'
import { Carousel, Modal } from 'antd'
import { collection, getDocs } from 'firebase/firestore/lite'
import { LoadingOutlined } from '@ant-design/icons'
import SVGSymbols from '~components/homepage/svg/icons/svg'
import CookiesPopup from '~components/homepage/sections/cookies-popup'

import {
  Container,
  ContentContainer,
  SectionWrapper,
} from '~components/homepage/styled/shared'

import LanguageContext from '~components/homepage/context/language-context'

import '../fonts/css/averta-font.css'

import GlobalStyle from '~components/homepage/sections/global-styles'
import Header from '~components/homepage/sections/header'
import FooterSection from '~components/homepage/sections/footer-section'
import { StoreItem, StoreItemAccessory } from '~src/models/store'

import { StoreItemComponent } from '~components/store'
import { KnifeDescription } from '~components/store/components/knife-description'
import {
  StyledDot,
  StyledDots,
  StyledModal,
} from '~components/store/components/styled'
import { ArrowSvg } from '~components/homepage/svg/icons/arrow'
import { SVGImage } from '~components/homepage/svg/SVGImage'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import OrderModal from '~components/store/components/order-modal'
import {
  appMaxWidth,
  appMaxWidthRaw,
  palette,
} from '~components/homepage/styled/constants'
import { storeDb } from '~src/api/store-api'
import { Helmet } from '~components/helmet'
import { AccessoryDescription } from '~components/store/components/accessory-description'
import { AccessoryItemComponent } from '~components/store/components/accessory-item'
import {
  beginCheckout,
  ItemType,
  viewItem,
  viewStoreItemList,
} from '~src/analytics'

const StyledContentContainer = styled(ContentContainer)`
  padding-top: 145px;
  overflow: unset;
`

const ItemsContainer = styled.div`
  position: relative;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 91px;

  @media (max-width: ${appMaxWidth}) {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

const StyledNextArrow = styled.span`
  width: 11px;
  right: -35px !important;
  transform: rotate(180deg);
  transform-origin: 50% 50%;
  z-index: 100;
  top: 50% !important;

  transition: 0.3s;

  svg {
    width: 11px;
  }

  &::before {
    display: none;
  }
`

const StyledPrevArrow = styled.span`
  left: -35px !important;
  width: 11px;
  z-index: 100;
  transform: none !important;
  transition: 0.3s;

  svg {
    width: 11px;
  }

  &::before {
    display: none;
  }
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 200px;
`

const StyledContainer = styled(Container)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

/*eslint-disable */
function NextArrow({ className, onClick }: any): ReactElement {
  return (
    <StyledNextArrow onClick={onClick} className={className}>
      <ArrowSvg />
    </StyledNextArrow>
  )
}

function PrevArrow({ className, style, onClick }: any): ReactElement {
  return (
    <StyledPrevArrow
      onClick={onClick}
      className={className}
      style={{ ...style }}
    >
      <ArrowSvg />
    </StyledPrevArrow>
  )
}

function carouselDots(dots: any): ReactElement {
  return (
    <StyledDots>
      {dots.map(
        (dot: ReactElement): ReactElement => (
          <StyledDot className="dot-wrap" key={`dot-${dot.key}`}>
            {dot}
          </StyledDot>
        )
      )}
    </StyledDots>
  )
}

const UpTitle = styled.div`
  font-size: 10px;
  color: ${palette.darkBlue};
  text-align: center;
  font-family: 'Averta-Bold', sans-serif;
`

const Title = styled.div`
  font-size: 25px;
  font-family: 'Averta-Bold', sans-serif;
  color: ${palette.darkBlue};
  text-align: center;
  margin-bottom: 25px;
  margin-top: 10px;
`

const SubTitle = styled.div`
  color: ${palette.darkBiege};
  font-size: 13px;
  text-align: center;
  line-height: 24px;
`

const StorePage = ({ t, i18n }): ReactElement => {
  const switchLang = (lng: string): void => i18n.changeLanguage(lng)
  const [storeItems, setStoreItems] = useState<StoreItem[]>([])
  const [storeAccessories, setStoreAccessories] = useState<
    StoreItemAccessory[]
  >([])

  const fetchStoreItems = async (): void => {
    const store = collection(storeDb, 'store')
    const items = await getDocs(store)
    const parsedItems = items.docs.map(doc => doc.data()) as StoreItem[]

    const accessories = collection(storeDb, 'accessory')
    const accessoriesItems = await getDocs(accessories)
    const parsedAccessoriesItems = accessoriesItems.docs.map(doc =>
      doc.data()
    ) as StoreItemAccessory[]

    viewStoreItemList(parsedItems, parsedAccessoriesItems)

    setStoreItems(parsedItems)
    setStoreAccessories(parsedAccessoriesItems)
  }

  useEffect(() => {
    fetchStoreItems()
  }, [])

  const [openDescription, setOpenDescription] = useState<
    Omit<StoreItem & { chunkIndex: number }, 'images'>
  >()
  const [openAccessoryDescription, setOpenAccessoryDescription] = useState<
    Omit<StoreItemAccessory & { chunkIndex: number }, 'images'>
  >()
  const [openPreview, setOpenPreview] = useState<React.ReactElement[]>()
  const [openAccessoryPreview, setOpenAccessoryPreview] = useState<
    React.ReactElement[]
  >()
  const [orderModalOpened, openOrderModal] = useState<number | string>()

  const chunked: StoreItem[][] | StoreItemAccessory[][] = chunk(
    [...storeItems, ...storeAccessories],
    3
  )

  useEffect(() => {
    if (openDescription) {
      setOpenAccessoryDescription(undefined)

      const index = storeItems.findIndex(({ id }) => id === openDescription.id)
      viewItem(
        'knife',
        { ...openDescription, images: [] },
        index,
        i18n.language === 'EN' ? 'EUR' : 'PLN'
      )
    } else if (openAccessoryDescription) {
      setOpenDescription(undefined)

      const index = storeAccessories.findIndex(
        ({ id }) => id === openAccessoryDescription.id
      )
      viewItem(
        'accessory',
        { ...openAccessoryDescription, images: [] },
        index + storeItems.length,
        i18n.language === 'EN' ? 'EUR' : 'PLN'
      )
    }
  }, [openDescription, openAccessoryDescription])

  useEffect(() => {
    if (orderModalOpened) {
      const findItem =
        storeItems.find(k => k.id === orderModalOpened) ||
        storeAccessories.find(k => k.id === orderModalOpened)

      const type: ItemType = findItem['knife'] ? 'knife' : 'accessory'
      beginCheckout(
        type,
        findItem,
        i18n.language === 'EN' ? 'EUR' : 'PLN',
        'klosy.pl/store'
      )
    }
  }, [orderModalOpened])

  const previewModal = (
    <StyledModal
      visible={!!openPreview || !!openAccessoryPreview}
      footer={null}
      title={null}
      closeIcon={<SVGImage svgProps={[KlosySVGId.cross, 'close', 12, 12]} />}
      maskClosable
      onCancel={(): void => {
        setOpenPreview(undefined)
        setOpenAccessoryPreview(undefined)
      }}
      destroyOnClose
    >
      <Carousel
        appendDots={carouselDots}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        arrows
      >
        {openPreview}
        {openAccessoryPreview}
      </Carousel>
    </StyledModal>
  )

  const orderModal = (
    <OrderModal
      active={
        !!storeItems.find(k => k.id === orderModalOpened) ||
        !!storeAccessories.find(k => k.id === orderModalOpened)
      }
      onCloseModal={(): void => {
        openOrderModal(null)
      }}
      item={
        storeItems.find(k => k.id === orderModalOpened) ||
        storeAccessories.find(k => k.id === orderModalOpened)
      }
    />
  )

  const renderKnifeDescription = (isMobile: boolean) =>
    openDescription && (
      <KnifeDescription
        {...openDescription}
        setOpenOrderModal={openOrderModal}
        isMobile={isMobile}
        onClose={(): void => setOpenDescription(undefined)}
      />
    )

  const renderAccessoryDescription = (isMobile: boolean) =>
    openAccessoryDescription && (
      <AccessoryDescription
        accessory={openAccessoryDescription}
        setOpenOrderModal={openOrderModal}
        isMobile={isMobile}
        onClose={(): void => setOpenAccessoryDescription(undefined)}
      />
    )

  const renderDescriptionModal = (
    <Modal
      visible={openDescription && window.innerWidth <= appMaxWidthRaw}
      footer={null}
      title={null}
      closeIcon={null}
      closable={false}
      width="100%"
      style={{
        top: '0px',
        margin: '0px',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        paddingBottom: 0,
        overflow: 'hidden',
      }}
      bodyStyle={{
        padding: '0px',
        overflow: 'hidden',
        // height: '100%',
      }}
    >
      {renderKnifeDescription(true)}
      {renderAccessoryDescription(true)}
    </Modal>
  )

  return (
    <SectionWrapper bgColor="#F7F5F1">
      <Helmet />
      <StyledContainer>
        <GlobalStyle />
        <LanguageContext.Provider value={{ t, lang: i18n.language }}>
          <CookiesPopup />
          <Header onSwitchLang={switchLang} />
          <SVGSymbols />
          {previewModal}
          {orderModal}
          {renderDescriptionModal}
          <StyledContentContainer maxWidth="1015px">
            <UpTitle>{t('header.store')}</UpTitle>
            <Title>{t('store.title')} </Title>

            <SubTitle>
              <div>{t('store.subtitle')}</div>
              <div>{t('store.subtitle2')}</div>
            </SubTitle>

            {!chunked.length ? (
              <LoadingWrapper>
                <LoadingOutlined style={{ fontSize: 30 }} spin />
              </LoadingWrapper>
            ) : (
              chunked.map(
                (itemsChunk, idx): React.ReactElement => {
                  return (
                    <>
                      {/* eslint-disable-next-line */}
                      <ItemsContainer key={`items-chunk-${idx}`}>
                        {itemsChunk.map(
                          (item, index): React.ReactElement => {
                            return item?.knife ? (
                              <StoreItemComponent
                                key={item.id}
                                {...item}
                                itemIndex={index}
                                hideFooter={
                                  openDescription?.chunkIndex === idx ||
                                  openAccessoryDescription?.chunkIndex === idx
                                }
                                selected={openDescription?.id === item.id}
                                onOpenDescription={(): void => {
                                  setOpenDescription({
                                    metal: item.metal,
                                    wood: item.wood,
                                    knife: item.knife,
                                    price: item.price,
                                    chunkIndex: idx,
                                    id: item.id,
                                    comment: item.comment,
                                  })
                                  setOpenAccessoryDescription(undefined)
                                }}
                                setOpenPreview={setOpenPreview}
                                openPreview={openPreview}
                              />
                            ) : (
                              <AccessoryItemComponent
                                onOpenDescription={(): void => {
                                  setOpenDescription(undefined)
                                  setOpenAccessoryDescription({
                                    ...item,
                                    chunkIndex: idx,
                                  })
                                }}
                                key={item.id}
                                setOpenPreview={setOpenAccessoryPreview}
                                openPreview={openAccessoryPreview}
                                hideFooter={
                                  openAccessoryDescription?.chunkIndex ===
                                    idx || openDescription?.chunkIndex === idx
                                }
                                selected={
                                  openAccessoryDescription?.id === item.id
                                }
                                accessory={item}
                                itemIndex={index}
                              />
                            )
                          }
                        )}
                      </ItemsContainer>
                      <div>
                        {openDescription &&
                          openDescription.chunkIndex === idx &&
                          renderKnifeDescription(false)}

                        {openAccessoryDescription &&
                          openAccessoryDescription.chunkIndex === idx &&
                          renderAccessoryDescription(false)}
                      </div>
                    </>
                  )
                }
              )
            )}
          </StyledContentContainer>
          <FooterSection />
        </LanguageContext.Provider>
      </StyledContainer>
    </SectionWrapper>
  )
}

export default withTranslation('translation')(StorePage)
