import React, { useContext } from 'react'
import { DarkButton } from '~components/homepage/styled/shared'
import LanguageContext from '~components/homepage/context/language-context'

import { SVGImage } from '~components/homepage/svg/SVGImage'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { StoreItemAccessory } from '~src/models/store'
import { langIndex } from '~components/homepage/utils'
import { DescriptionContainer } from './styled'

export const AccessoryDescription: React.FC<Omit<
  {
    chunkIndex: number
    onClose: () => void
    setOpenOrderModal: (id: string) => void
    isMobile: boolean
    accessory: StoreItemAccessory
  },
  'previewImage'
>> = ({
  accessory,
  onClose,
  setOpenOrderModal,
  isMobile,
}): React.ReactElement => {
  const { lang, t } = useContext(LanguageContext)

  const mobileStyle = {
    overflow: 'auto',
    height: '100vh',
    display: 'block',
  }

  const lngIndex = langIndex(lang)

  return (
    <DescriptionContainer
      style={isMobile ? mobileStyle : undefined}
      isMobile={isMobile}
      gridRule="1fr"
    >
      <div
        className="close"
        onKeyPress={(): void => {}}
        role="button"
        tabIndex={0}
        onMouseDown={onClose}
      >
        <SVGImage svgProps={[KlosySVGId.cross, 'close', 12, 12]} />
      </div>
      <div className="knife-description">
        <div className="title"> {accessory.name[lngIndex]}</div>
        <p>{accessory.description[lngIndex]}</p>

        <i> {accessory.comment} </i>
        <div className="accessory-footer-description">
          <div className="price accessory-price">
            <span className="bold">{t('store.price')}</span>:{' '}
            {accessory.price[lngIndex]} {lang === 'PL' ? 'zł' : '€'}
          </div>

          <DarkButton
            className="order-button"
            onClick={(): void => setOpenOrderModal(accessory.id)}
          >
            {t('store.order')}
          </DarkButton>
        </div>
      </div>
    </DescriptionContainer>
  )
}
