import React, { FC, ReactElement } from 'react'

export const SoldoutEnglish: FC = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58.854"
    height="58.854"
    viewBox="0 0 58.854 58.854"
  >
    <g
      id="Group_408"
      data-name="Group 408"
      transform="translate(45.022 -527.904) rotate(45)"
    >
      <path
        id="Path_806"
        data-name="Path 806"
        d="M405.655,396.587H360.476l-10.494,8.533,10.494,8.533h45.179l10.494-8.533Z"
        fill="#1d3041"
      />
      <g id="Group_406" data-name="Group 406">
        <path
          id="Path_807"
          data-name="Path 807"
          d="M368.556,405.784c-1.172-.409-2.491-1.046-2.491-2.525a2.42,2.42,0,0,1,2.605-2.252,3.423,3.423,0,0,1,2.593,1.263l-.944.932a2.3,2.3,0,0,0-1.649-.9,1.1,1.1,0,0,0-1.217.956c0,.694.625.91,1.717,1.32,1.217.454,2.23,1,2.23,2.444s-1.274,2.219-2.776,2.219a3.482,3.482,0,0,1-2.912-1.627l1.024-.864a2.429,2.429,0,0,0,1.888,1.194c.683,0,1.388-.272,1.388-.922C370.012,406.353,369.5,406.114,368.556,405.784Z"
          fill="#f7f5f1"
        />
        <path
          id="Path_808"
          data-name="Path 808"
          d="M382.665,405.124a4.027,4.027,0,1,1-4.026-4.117A3.964,3.964,0,0,1,382.665,405.124Zm-1.388,0a2.644,2.644,0,1,0-2.638,2.821A2.656,2.656,0,0,0,381.277,405.124Z"
          fill="#f7f5f1"
        />
        <path
          id="Path_809"
          data-name="Path 809"
          d="M386.16,401.144h1.365v6.665h3.389v1.3H386.16Z"
          fill="#f7f5f1"
        />
        <path
          id="Path_810"
          data-name="Path 810"
          d="M400.622,405.124a3.812,3.812,0,0,1-3.935,3.982h-2.571v-7.962h2.571A3.81,3.81,0,0,1,400.622,405.124Zm-1.388,0a2.528,2.528,0,0,0-2.525-2.683h-1.228v5.368h1.228A2.535,2.535,0,0,0,399.234,405.124Z"
          fill="#f7f5f1"
        />
      </g>
    </g>
  </svg>
)
