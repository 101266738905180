import React, { ReactElement, useContext } from 'react'
import styled from 'styled-components'
import { Carousel } from 'antd'

import { StoreItemAccessory } from '~src/models/store'

import LanguageContext from '~components/homepage/context/language-context'
import { KnifeSizeImage } from '~components/homepage/styled/grid-materials'
import { palette } from '~components/homepage/styled/constants'
import { MagnifySvg } from '~components/homepage/svg/icons/magnify'
import { Soldout } from '~components/homepage/svg/icons/soldout'
import { SoldoutEnglish } from '~components/homepage/svg/icons/soldOutEnglish'
import { langIndex } from '~components/homepage/utils'
import {
  NextArrow,
  PrevArrow,
  StyledNextArrow,
  StyledPrevArrow,
} from './styled'

const KnifeDraft = styled.div<{ active: boolean }>`
  color: inherit;
  background: ${palette.paleGray};
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  .single-knife-image {
    height: unset;
    padding-top: 10px;
    svg {
      stroke: red;
      color: red;
    }

    .color-transition {
      fill: ${({ active }): string => (active ? 'white' : '#f7f5f1')};
    }
  }
`

const Materials = styled.div`
  display: grid;
  justify-content: center;
  font-size: 11px;
  color: ${palette.darkBlue};
  margin-top: 16px;
  min-height: 17px;

  gap: 10px;
`

const Price = styled.div`
  font-size: 14px;
  font-family: 'Averta-Bold', sans-serif;
  color: ${palette.darkBlue};
  margin-top: 9px;
`

const PriceDivider = styled.div`
  width: 20px;
  height: 2px;
  background: ${palette.inputBorder};
  margin: 0 auto;
  margin-bottom: 6px;
`

const MagnifyWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 7px;
  transition: 0.3s;
  opacity: 0;
  z-index: 99;
  cursor: pointer;
`

const ItemContainer = styled.div`
  max-width: 325px;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  position: relative;

  .knife-name {
    font-size: 11px;
    font-family: 'Averta-Bold', sans-serif;
    color: ${palette.darkBlue};
    margin-top: 15px;
  }

  &:hover {
    ${StyledNextArrow}, ${StyledPrevArrow}, ${MagnifyWrapper} {
      opacity: 1;
    }
  }

  .accessory-icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #F7F5F1;
    border: 1px solid ${palette.inputBorder};
  }

  .accessory-icon-wrapper {
    padding-top: 0px;
    justify-content: center;
    display: flex;
  }
`

const SelectedStick = styled.div`
  position: absolute;
  height: 28px;
  width: 1px;
  left: 40px;
  bottom: 0px;
  background: ${palette.darkBlue};
`

const SoldoutIcon = styled.div<{ eng: boolean }>`
  position: absolute;
  top: 0px;
  z-index: 10;
  right: 0px;
  transform: ${({ eng }): string =>
    eng
      ? 'translateX(25%) translateY(-25%)'
      : 'translateX(20%) translateY(-20%)'};
`

export const AccessoryItemComponent: React.FC<{
  itemIndex: number
  onOpenDescription: () => void
  hideFooter: boolean
  selected: boolean
  accessory: StoreItemAccessory
  setOpenPreview: (id: React.ReactElement[]) => void
}> = ({
  accessory,
  onOpenDescription,
  hideFooter,
  selected,
  setOpenPreview,
}): ReactElement => {
  const { lang, t } = useContext(LanguageContext)

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: (Math.floor(Math.random() * 5) + 10) * 1000,
  }

  const renderSlides = (): object[] =>
    accessory.images.map((url): object => (
      <div key={url}>
        <img
          src={url}
          alt={accessory.name[0]}
          style={{
            width: '100%',
          }}
        />
      </div>
    ))

  const lngIndex = langIndex(lang)

  return (
    <ItemContainer
      style={{ pointerEvents: accessory.soldOut ? 'none' : 'unset' }}
    >
      {accessory.soldOut && (
        <SoldoutIcon eng={lang !== 'PL'}>
          {lang === 'PL' ? <Soldout /> : <SoldoutEnglish />}
        </SoldoutIcon>
      )}
      <MagnifyWrapper onClick={(): void => setOpenPreview(renderSlides())}>
        <MagnifySvg />
      </MagnifyWrapper>

      <Carousel
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        arrows
        {...sliderSettings}
        style={{
          height: 217,
          overflow: 'hidden',
          opacity: accessory.soldOut ? '0.5' : 1,
        }}
      >
        {renderSlides()}
      </Carousel>
      <KnifeDraft
        active={selected}
        onClick={onOpenDescription}
        style={{ opacity: accessory.soldOut ? '0.5' : 1 }}
      >
        {selected && <SelectedStick />}
        <KnifeSizeImage
          className={`${accessory.name} single-knife-image accessory-icon-wrapper`}
        >
          <div className="accessory-icon"></div>
        </KnifeSizeImage>
      </KnifeDraft>

      {!hideFooter && (
        <>
          <div
            style={{ opacity: accessory.soldOut ? '0.5' : 1 }}
            className="knife-name"
          >
            {accessory.name[lngIndex]}
          </div>
          <Materials style={{ opacity: accessory.soldOut ? '0.5' : 1 }}>
            {`${accessory.subtitle[lngIndex]}`}
          </Materials>
          <Price style={{ opacity: accessory.soldOut ? '0.5' : 1 }}>
            <PriceDivider />
            {accessory.price[lngIndex]} {t(`currency`)}
          </Price>
        </>
      )}
    </ItemContainer>
  )
}
