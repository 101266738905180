import React from 'react'
import { Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import styled from 'styled-components'
import { palette } from '~components/homepage/styled/constants'
import { ArrowSvg } from '~components/homepage/svg/icons/arrow'

export const StyledModal = styled(Modal)<ModalProps>`
  font-size: 13px;
  text-align: center;
  padding: 0px 20px;
  max-width: 1100px !important;
  width: auto !important;

  .ant-modal-close-x button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .ant-modal-body {
    background-color: ${palette.beige};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    padding: 46px 125px 40px;
    text-align: center;
  }

  .slick-slider {
    max-height: inherit !important;
  }
`

export const StyledDot = styled.span`
  margin: 0px 3px;

  li {
    button {
      opacity: 0 !important;
    }
    opacity: 0.5;

    &::before {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 100px;
      background-color: ${palette.darkBlue};
    }

    &.slick-active {
      opacity: 1;
    }
  }
`

export const StyledDots = styled.ul`
  position: relative;
  bottom: -10px !important;
`

export const DescriptionContainer = styled.div<{
  isMobile: boolean
  gridRule?: string
}>`
  width: 100%;
  background: white;
  height: auto;
  text-align: left;
  color: ${palette.darkBiege};
  font-family: 'Averta-Regular', sans-serif;
  line-height: 23px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);

  display: grid;
  grid-template-columns: ${({ gridRule = '2fr 1px 1fr' }): string => gridRule};
  position: relative;

  padding-top: ${({ isMobile }): string => (isMobile ? '50px' : 'inherit')};

  .knife-description,
  .params-container {
    padding: 50px;
    padding-top: ${({ isMobile }): string => (isMobile ? '0px' : '50px')};
  }

  font-size: 13px;

  .params-container {
    font-size: 11px;
  }

  .price {
    font-size: 14px;
  }

  .title {
    font-size: 14px;
    font-family: 'Averta-Bold', sans-serif;
    margin-bottom: 10px;
    color: ${palette.darkBlue};
  }

  .accessory-footer-description {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
  }

  .accessory-price {
    font-size: 14px;
    font-family: 'Averta-Bold', sans-serif;
    color: ${palette.darkBlue};
  }

  .divider {
    background: ${palette.darkBlue};
    margin: 50px 0px;
    display: ${({ isMobile }): string => (isMobile ? 'none' : 'block')};
  }

  .knife-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .material {
    display: grid;
    gap: 10px;
    cursor: pointer;
  }

  .knife-params {
    color: ${palette.darkBlue};
    font-size: 11px;

    & > div {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 15px;
      }
    }
  }

  .material-name {
    font-size: 11px;
    color: ${palette.darkBlue};
    font-family: 'Averta-Bold', sans-serif;
  }

  .materials-preview {
    display: flex;
    flex-direction: row;
    gap: 30px;
    position: relative;
    top: 5px;

    .material {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .material-image > div {
      border-radius: 50px;
    }
  }

  .h-divider {
    background: ${palette.inputBorder};
    margin-top: -30px;
    height: 1px;
    display: ${({ isMobile }): string => (isMobile ? 'none' : 'block')};
  }

  .order-button {
    padding-top: 3px;
    padding-bottom: 3px;
    line-height: 22px;
    width: 100px;
    text-align: center;
  }

  .material-description-container {
    background: white;
    top: ${({ isMobile }): string => (isMobile ? 'auto' : 'calc(100% - 1px)')};
    z-index: 99;
    grid-template-columns: 2fr 1fr;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
    position: ${({ isMobile }): string => (isMobile ? 'inherit' : 'absolute')};
    display: ${({ isMobile }): string => (isMobile ? 'block' : 'grid')};
    padding-bottom: ${({ isMobile }): string =>
      isMobile ? '100px' : 'inherit'};

    .material-params,
    .description-text {
      padding: 50px;
      padding-bottom: 0px;
      padding-top: 0px;
    }

    .description-text {
      padding-bottom: 20px;
    }
  }
  .close {
    position: absolute;
    top: 25px;
    right: 20px;
    cursor: pointer;
  }
`

export const StyledNextArrow = styled.span`
  width: 11px;
  right: 5px !important;
  transform: rotate(180deg);
  transform-origin: 50% 50%;
  z-index: 100;
  top: 50% !important;

  opacity: 0;
  transition: 0.3s;

  svg {
    width: 11px;
    path {
      stroke: white;
    }
  }

  &::before {
    display: none;
  }
`

export const StyledPrevArrow = styled.span`
  left: 5px !important;
  width: 11px;
  z-index: 100;
  transform: none !important;
  transition: 0.3s;
  opacity: 0;

  svg {
    width: 11px;
    path {
      stroke: white;
    }
  }

  &::before {
    display: none;
  }
`

/*eslint-disable */
export function NextArrow({ className, onClick }: any): ReactElement {
  return (
    <StyledNextArrow onClick={onClick} className={className}>
      <ArrowSvg />
    </StyledNextArrow>
  )
}

export function PrevArrow({ className, style, onClick }: any): ReactElement {
  return (
    <StyledPrevArrow
      onClick={onClick}
      className={className}
      style={{ ...style }}
    >
      <ArrowSvg />
    </StyledPrevArrow>
  )
}
