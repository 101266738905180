import React, { FC, ReactElement } from 'react'

export const MagnifySvg: FC = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.43"
    height="18.608"
    viewBox="0 0 18.43 18.608"
  >
    <g
      id="Component_67_1"
      data-name="Component 67 – 1"
      transform="translate(0.5 0.5)"
    >
      <line
        id="Line_498"
        data-name="Line 498"
        x2="5.766"
        y2="5.766"
        transform="translate(11.811 11.988)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <circle
        id="Ellipse_269"
        data-name="Ellipse 269"
        cx="7.076"
        cy="7.076"
        r="7.076"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <path
        id="Path_811"
        data-name="Path 811"
        d="M576.585,329.481a4.583,4.583,0,0,1,4.577-4.577"
        transform="translate(-574.085 -322.404)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </svg>
)
